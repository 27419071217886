<script setup lang="ts">
    import {ref} from 'vue';
    import useCpBem from '~/composables/useCpBem';
    import CpText from '~/components/cp/CpText/CpText.vue';

    withDefaults(defineProps<{
      kind: 'xsmall'|'small'|'overlay',
      bodyText?: string,
      fullSize?: boolean,
      noOpacity?: boolean,
      /* This prop accepts html color codes e.g #000 */
      scrim?: boolean | string
    }>(), {
      fullSize: false,
      noOpacity: false,
    });
    const overlay = ref(true);
    const {b, em, e} = useCpBem('cp-loader');
</script>

<template>
    <div :class="b">
        <v-overlay
            v-if="fullSize"
            v-model="overlay"
            persistent
            :class="em('full', { noOpacity })"
            :opacity="0"
            :scrim="scrim ? scrim : false"
        >
            <div :class="e('spinner-container')">
                <div :class="em('spinner', {[kind]: true})" />
                <CpText v-if="bodyText" variant="body-bold" block :class="e('text')">
                    {{ bodyText }}
                </CpText>
            </div>
        </v-overlay>
        <div v-else :class="em('container', {'overlay': kind == 'overlay' })">
            <div :class="em('spinner', {[kind]: true})" />
            <CpText v-if="bodyText" variant="body-bold" block :class="e('text')">
                {{ bodyText }}
            </CpText>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .cp-loader {
      padding: 16px;
      width: fit-content;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        &--overlay {
          background: rgba(255, 255, 255, 0.8);
        }
      }

      &__text {
        text-align: center;
        padding-top: 16px;
      }
      &__spinner {
        border: 8px solid var(--color-neutral-extra-light);
        border-radius: 50%;
        border-right-color: var(--color-primary);
        animation: spinner-object 1s infinite linear;
        &--xsmall {
          border: 6px solid var(--color-neutral-extra-light);
          border-right-color: var(--color-primary);
          width: 32px;
          height: 32px;
        }
        &--small {
          width: 48px;
          height: 48px;
        }
        &--overlay {
          width: 48px;
          height: 48px;
        }
      }

      &__full--no-opacity:deep(.v-overlay__scrim) {
        opacity: 1;
        background: #fafafa;
      }

      &__full {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: $cp-gutter;
      }
    }

    @keyframes spinner-object {
      to {
        transform: rotate(1turn);
      }
    }
</style>
